import Cookies from 'js-cookie';

export const headersConfig = {
    headers: {
        'Authorization': Cookies.get('token'),
        // 'Authorization': `Bearer ${Cookies.get('token')}`,
        // 'Admin-Role': Cookies.get('username'),
        'Content-Type': 'application/json',
    },
};

export const multiHeadersConfig = {
    headers: {
        'Authorization': Cookies.get('token'),
        // 'Admin-Role': Cookies.get('username'),
        'Content-Type': 'multipart/form-data',
    },
};

