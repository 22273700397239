import { configureStore } from '@reduxjs/toolkit'
import adminsSlice from './slices/admins-slices'
import countriesSlices from './slices/countries-slices'
import citiesSlices from './slices/cities-slices'
import deliverymenSlices from './slices/deliverymen-slices'
import categoriesSlices from './slices/categories-slices'
import productsSlices from './slices/products-slices'
import membersSlices from './slices/members-slices'
import currenciesSlices from './slices/currencies-slices'
import videoSlices from './slices/video-slices'
import productsListSlices from './slices/productsList-slices'
import branchesSlices from './slices/branches-slices'
import videosCategoriesSlices from './slices/videosCategories-slices'
import membersRequestSlices from './slices/membersRequest-slices'

export const store = configureStore({
  reducer: {
    admins: adminsSlice,
    countries: countriesSlices,
    cities: citiesSlices,
    currencies: currenciesSlices,
    deliverymen: deliverymenSlices,
    members: membersSlices,
    categories: categoriesSlices,
    products: productsSlices,
    videosCategories: videosCategoriesSlices,
    videos: videoSlices,
    productsList: productsListSlices,
    branches: branchesSlices,
    membersRequest: membersRequestSlices,
  },
})
