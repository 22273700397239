import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { loginApi } from '../../constant';
import Cookies from 'js-cookie';


export const adminLogin = createAsyncThunk(
  'admins/adminLogin',
  async (admin) => {
    const response = await axios.post(loginApi, admin);
    return response.data;
  }
);

const adminsSlice = createSlice({
  name: 'admins',
  initialState: {
    message: {},
    username: '',
    password: '',
    loadingButton: false,
    error: null,
  },
  reducers: {
    setLoadingButton(state, action) {
      state.loadingButton = action.payload;
    },
    // inputs data
    setUsername(state, action) {
      state.username = action.payload;
    },
    setPassword(state, action) {
      state.password = action.payload;
    },
  },


  extraReducers: (builder) => {
    // login
    builder.addCase(adminLogin.pending, (state, action) => {
      state.loadingButton = true;
    });
    builder.addCase(adminLogin.rejected, (state, action) => {
      state.loadingButton = false;
      state.error = action.error;
      const newMsg = {
        type: 'error',
        text: 'اسم المستخدم او كلمة المرور غير صحيحة',
      };
      state.message = newMsg;
    });
    builder.addCase(adminLogin.fulfilled, (state, action) => {
      Cookies.set('token', action.payload.token, { expires: 365 }); // 'expires' sets the cookie expiration in days
      state.loadingButton = false;
      window.location = '/';
    });
  },
});


export const {
  setUsername,
  setPassword,
  setLoadingButton,
} = adminsSlice.actions;
export default adminsSlice.reducer;
