import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getCurrenciesApi, insertCurrencyApi, deleteCurrenciesApi, updateCurrencyApi, deleteCurrencyApi } from '../../constant';
import { headersConfig } from '../../config/config';


export const insertCurrency = createAsyncThunk(
    'currencies/insertCurrency',
    async (currency) => {
        const response = await axios.post(insertCurrencyApi,
            currency,
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);

export const fetchCurrencies = createAsyncThunk(
    'currencies/fetchCurrencies',
    async () => {
        const response = await axios.post(getCurrenciesApi,
            {},
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);

export const updateCurrency = createAsyncThunk(
    'currencies/updateCurrency',
    async (currency) => {
        const response = await axios.post(updateCurrencyApi,
            currency,
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);


export const deleteCurrencies = createAsyncThunk(
    'currencies/deleteCurrencies',
    async (ids) => {
        const body = {
            ids,
        }
        const response = await axios.post(deleteCurrenciesApi,
            body,
            {
                headers: headersConfig.headers,
            },
        );
        return ids;
    }
);
export const deleteCurrency = createAsyncThunk(
    'currencies/deleteCurrency',
    async (id) => {
        const body = {
            id,
        }
        const response = await axios.post(deleteCurrencyApi,
            body,
            {
                headers: headersConfig.headers,
            },
        );
        return id;
    }
);


const currenciesSlices = createSlice({
    name: 'currencies',
    initialState: {
        currencies: [],
        filteredCurrencies: [],
        message: {},
        name: '',
        currentCurrencyId: null,
        countryId: null,
        insertModal: false,
        updateModal: false,
        deleteModal: false,
        deleteAllModal: false,
        isLoading: false,
        loadingButton: false,
        error: null,
    },
    reducers: {
        FilteredData(state, action) {
            const filtered = state.cities.filter((item) =>
                Object.values(item).some((val) =>
                    val.toString().toLowerCase().includes(action.payload.toLowerCase())
                )
            );
            state.filteredCurrencies = filtered;
        },

        setInsertModal(state, action) {
            state.insertModal = action.payload;
        },
        setUpdateModal(state, action) {
            state.updateModal = action.payload;
        },
        setDeleteModal(state, action) {
            state.deleteModal = action.payload;
        },
        setDeleteAllModal(state, action) {
            state.deleteAllModal = action.payload;
        },
        setLoadingButton(state, action) {
            state.loadingButton = action.payload;
        },
        // inputs data
        setName(state, action) {
            state.name = action.payload;
        },
        setCountryId(state, action) {
            state.countryId = action.payload;
        },
        setCurrentCurrencyId(state, action) {
            state.currentCurrencyId = action.payload;
        },
    },


    extraReducers: (builder) => {
        // fetch data
        builder.addCase(fetchCurrencies.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchCurrencies.fulfilled, (state, action) => {
            state.cities = action.payload;
            state.filteredCurrencies = action.payload;
            state.isLoading = false;
        });

        builder.addCase(fetchCurrencies.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        });

        // insert data
        builder.addCase(insertCurrency.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(insertCurrency.rejected, (state, action) => {
            state.loadingButton = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ ما حاول فيما بعد',
            };
            state.message = newMsg;
        });
        builder.addCase(insertCurrency.fulfilled, (state, action) => {
            state.cities.push(action.payload);
            state.filteredCurrencies = state.cities;
            state.loadingButton = false;
            state.insertModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'insert',
                text: 'تمت الأضافة بنجاح',
            };
            state.message = newMsg;
        });

        // delete data multie
        builder.addCase(deleteCurrencies.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(deleteCurrencies.rejected, (state, action) => {
            state.loadingButton = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteCurrencies.fulfilled, (state, action) => {
            const deletedCityIds = action.payload;
            state.cities = state.cities.filter((city) => !deletedCityIds.includes(city.id));
            state.filteredCurrencies = state.filteredCurrencies.filter((city) => !deletedCityIds.includes(city.id));
            state.deleteAllModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'delete',
                text: 'تم الحذف بنجاح',
            };
            state.message = newMsg;
        });

        // delete data single
        builder.addCase(deleteCurrency.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(deleteCurrency.rejected, (state, action) => {
            state.error = action.error;
            state.loadingButton = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteCurrency.fulfilled, (state, action) => {
            state.cities = state.cities.filter((city) => city.id !== action.payload);
            state.filteredCurrencies = state.filteredCurrencies.filter((city) => city.id !== action.payload);
            state.deleteModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'delete',
                text: 'تم الحذف بنجاح',
            };
            state.message = newMsg;
        });

        // update data
        builder.addCase(updateCurrency.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(updateCurrency.rejected, (state, action) => {
            state.error = action.error;
            state.loadingButton = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(updateCurrency.fulfilled, (state, action) => {
            const updatedCity = action.payload;
            state.loadingButton = false;
            // Update the existing city in the state
            const updatedCountries = state.cities.map((city) =>
                city.id === updatedCity.id ? updatedCity : city
            );
            state.cities = updatedCountries;

            // Update the filtered cities as well, if applicable
            const updatedFilteredCurrencies = state.filteredCurrencies.map((city) =>
                city.id === updatedCity.id ? updatedCity : city
            );
            state.filteredCurrencies = updatedFilteredCurrencies;

            // Add a success message if needed
            const newMsg = {
                type: 'update',
                text: 'تم التحديث بنجاح',
            };
            state.message = newMsg;
            state.loadingButton = false;
            state.updateModal = false;
        });


    },

});

export const {
    FilteredData,
    setInsertModal,
    setUpdateModal,
    setDeleteModal,
    setDeleteAllModal,
    setName,
    setCountryId,
    setCurrentCurrencyId,
    setLoadingButton,
} = currenciesSlices.actions;
export default currenciesSlices.reducer;
