import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { insertVideosCategoryApi, deleteVideosCategoriesApi, updateVideosCategoryApi, deleteVideosCategoryApi, getVideosCategoriesApi } from '../../constant';
import { headersConfig } from '../../config/config';



export const insertCategory = createAsyncThunk(
    'videosCategories/insertCategory',
    async (category) => {
        const response = await axios.post(insertVideosCategoryApi,
            category,
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);


export const fetchCategories = createAsyncThunk(
    'videosCategories/fetchCategories',
    async () => {
        const response = await axios.post(getVideosCategoriesApi,
            {},
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;

        // return useFetchData(getCategoriesApi,
        //     {
        //         headers: headersConfig.headers,
        //     },
        //     );
    }
);

export const updateCategory = createAsyncThunk(
    'videosCategories/updateCategory',
    async (category) => {
        const response = await axios.post(updateVideosCategoryApi, category,
            {
                headers: headersConfig.headers,
            },

        );
        return response.data;
    }
);


export const deleteCategories = createAsyncThunk(
    'videosCategories/deleteCategories',
    async (ids) => {
        const body = {
            ids,
        }
        const response = await axios.post(deleteVideosCategoriesApi,
            body,
            {
                headers: headersConfig.headers,
            },
        );
        return ids;
    }
);


export const deleteCategory = createAsyncThunk(
    'videosCategories/deleteCategory',
    async (id) => {
        const body = {
            id,
        }
        const response = await axios.post(deleteVideosCategoryApi,
            body,
            {
                headers: headersConfig.headers,
            },
        );
        return id;
    }
);


const videosCategoriesSlices = createSlice({
    name: 'videosCategories',
    initialState: {
        categories: [],
        filteredCategories: [],
        message: {},
        name: '',
        currentCategoryId: null,
        insertModal: false,
        updateModal: false,
        deleteModal: false,
        deleteAllModal: false,
        isLoading: false,
        loadingButton: false,
        error: null,
    },
    reducers: {
        FilteredData(state, action) {
            const filtered = state.categories.filter((item) =>
                Object.values(item).some((val) =>
                    val.toString().toLowerCase().includes(action.payload.toLowerCase())
                )
            );
            state.filteredCategories = filtered;
        },

        setInsertModal(state, action) {
            state.insertModal = action.payload;
        },
        setUpdateModal(state, action) {
            state.updateModal = action.payload;
        },
        setDeleteModal(state, action) {
            state.deleteModal = action.payload;
        },
        setDeleteAllModal(state, action) {
            state.deleteAllModal = action.payload;
        },
        setLoadingButton(state, action) {
            state.loadingButton = action.payload;
        },
        // inputs data
        setName(state, action) {
            state.name = action.payload;
        },
        setCurrentCategoryId(state, action) {
            state.currentCategoryId = action.payload;
        },
    },


    extraReducers: (builder) => {
        // fetch data
        builder.addCase(fetchCategories.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchCategories.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        });
        builder.addCase(fetchCategories.fulfilled, (state, action) => {
            state.categories = action.payload;
            state.filteredCategories = action.payload;
            state.isLoading = false;
        });

        // insert data
        builder.addCase(insertCategory.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(insertCategory.rejected, (state, action) => {
            state.loadingButton = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ ما حاول فيما بعد',
            };
            state.message = newMsg;
        });
        builder.addCase(insertCategory.fulfilled, (state, action) => {
            state.categories.push(action.payload);
            state.filteredCategories = state.categories;
            state.loadingButton = false;
            state.insertModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'insert',
                text: 'تمت الأضافة بنجاح',
            };
            state.message = newMsg;
        });

        // delete data multie
        builder.addCase(deleteCategories.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(deleteCategories.rejected, (state, action) => {
            state.loadingButton = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteCategories.fulfilled, (state, action) => {
            const deletedCategoriesIds = action.payload;
            state.categories = state.categories.filter((category) => !deletedCategoriesIds.includes(category.id));
            state.filteredCategories = state.filteredCategories.filter((category) => !deletedCategoriesIds.includes(category.id));
            state.deleteAllModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'delete',
                text: 'تم الحذف بنجاح',
            };
            state.message = newMsg;
        });

        // delete data single
        builder.addCase(deleteCategory.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(deleteCategory.rejected, (state, action) => {
            state.error = action.error;
            state.loadingButton = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteCategory.fulfilled, (state, action) => {
            state.categories = state.categories.filter((city) => city.id !== action.payload);
            state.filteredCategories = state.filteredCategories.filter((city) => city.id !== action.payload);
            state.deleteModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'delete',
                text: 'تم الحذف بنجاح',
            };
            state.message = newMsg;
        });
        // update data
        builder.addCase(updateCategory.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(updateCategory.rejected, (state, action) => {
            state.error = action.error;
            state.loadingButton = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(updateCategory.fulfilled, (state, action) => {
            const updatedCategory = action.payload;
            // Update the existing city in the state
            const updatedCountries = state.categories.map((city) =>
                city.id === updatedCategory.id ? updatedCategory : city
            );
            state.categories = updatedCountries;

            // Update the filtered categories as well, if applicable
            const updatedFilteredCategories = state.filteredCategories.map((city) =>
                city.id === updatedCategory.id ? updatedCategory : city
            );
            state.filteredCategories = updatedFilteredCategories;

            // Add a success message if needed
            const newMsg = {
                type: 'update',
                text: 'تم التحديث بنجاح',
            };
            state.message = newMsg;
            state.loadingButton = false;
            state.updateModal = false;
        });

    },

});


export const {
    FilteredData,
    setInsertModal,
    setUpdateModal,
    setDeleteModal,
    setDeleteAllModal,
    setName,
    setCurrentCategoryId,
    setLoadingButton,
} = videosCategoriesSlices.actions;
export default videosCategoriesSlices.reducer;
