import React, { useEffect, useState, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { CSpinner } from '@coreui/react';
import Cookies from 'js-cookie';
import './scss/style.scss';
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Login = React.lazy(() => import('./views/pages/Login'));

const loading = (
  <div className="d-flex justify-content-center align-items-center mt-5">
    <CSpinner />
  </div>
);

const ProtectedRoute = ({ element }) => {
  const token = Cookies.get('token');
  if (!token) {
    return <Navigate to="/login" />;
  }

  return element;
};

const App = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Check if the token cookie exists
    const savedToken = Cookies.get('token');
    if (savedToken) {
      setToken(savedToken);
    }
  }, []);

  return (
    <Router>
      <Suspense fallback={loading}>
        <Routes>
          <Route
            path="/login"
            name="تسجيل الدخول"
            element={<Login />}
            // element={token ? <Navigate to="/" /> : <Login />}
          />
          <Route
            path="*"
            name="Home"
            element={<ProtectedRoute element={<DefaultLayout />} />}
          />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
