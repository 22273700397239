import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { insertProductApi, updateProductApi, deleteProductsApi, deleteProductApi, getProductsApi, productDetailsApi, updateProductImageApi, updateProductPdfApi } from '../../constant';
import { headersConfig, multiHeadersConfig } from '../../config/config';


export const insertProduct = createAsyncThunk(
    'products/insertProduct',
    async (product) => {
        const formData = new FormData();
        formData.append('name', product.name);
        formData.append('subname', product.subname);
        formData.append('details', product.details);
        formData.append('image', product.image);
        formData.append('video_link', product.video_link);
        formData.append('category_id', product.category_id);
        formData.append('pdf', product.pdf);

        const dataListJson = JSON.stringify(product.dataList);
        formData.append('dataList', dataListJson);

        await new Promise(resolve => setTimeout(resolve, 0));
        const response = await axios.post(insertProductApi, formData, {
            headers: multiHeadersConfig.headers,
        });
        return response.data;
    }
);

export const updateProductImage = createAsyncThunk(
    'products/updateProductImage',
    async (product) => {
        const formData = new FormData();
        formData.append('id', product.id);
        formData.append('image', product.image);

        await new Promise(resolve => setTimeout(resolve, 0));
        const response = await axios.post(updateProductImageApi, formData, {
            headers: multiHeadersConfig.headers,
        });
        return response.data;
    }
);

export const updateProductPdf = createAsyncThunk(
    'products/updateProductPdf',
    async (product) => {
        const formData = new FormData();
        formData.append('id', product.id);
        formData.append('pdf', product.pdf);

        await new Promise(resolve => setTimeout(resolve, 0));
        const response = await axios.post(updateProductPdfApi, formData, {
            headers: multiHeadersConfig.headers,
        });
        return response.data;
    }
);


export const fetchProducts = createAsyncThunk(
    'products/fetchProducts',
    async () => {
        const response = await axios.post(getProductsApi,
            {},
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);


export const fetchProductDetails = createAsyncThunk(
    'products/fetchProductDetails',
    async (productId) => {
        const response = await axios.post(productDetailsApi,
            { product_id: productId },
            {
                headers: headersConfig.headers,
            },

        );
        return response.data;
    }
);

export const updateProduct = createAsyncThunk(
    'products/updateProduct',
    async (product) => {
        const response = await axios.post(updateProductApi,
            product,
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);


export const deleteProducts = createAsyncThunk(
    'products/deleteProducts',
    async (ids) => {
        const body = {
            ids,
        }
        const response = await axios.post(deleteProductsApi,
            body,
            {
                headers: headersConfig.headers,
            },
        );
        return ids;
    }
);


export const deleteProduct = createAsyncThunk(
    'products/deleteProduct',
    async (id) => {
        const body = {
            id,
        }
        const response = await axios.post(deleteProductApi,
            body,
            {
                headers: headersConfig.headers,
            },

        );
        return id;
    }
);


const productsSlices = createSlice({
    name: 'products',
    initialState: {
        products: [],
        filteredProducts: [],
        productDetails: [],
        productList: [],
        message: {},
        name: '',
        subName: '',
        details: '',
        videoLink: '',
        currentProductId: null,
        categoryId: null,
        insertModal: false,
        deleteModal: false,
        deleteAllModal: false,
        updateImageModal: false,
        updatePdfModal: false,
        isLoading: false,
        loadingButton: false,
        error: null,
    },
    reducers: {
        FilteredData(state, action) {
            const filtered = state.products.filter((item) =>
                Object.values(item).some((val) =>
                    val.toString().toLowerCase().includes(action.payload.toLowerCase())
                )
            );
            state.filteredProducts = filtered;
        },

        setInsertModal(state, action) {
            state.insertModal = action.payload;
        },
        setDeleteModal(state, action) {
            state.deleteModal = action.payload;
        },
        setDeleteAllModal(state, action) {
            state.deleteAllModal = action.payload;
        },
        setUpdateImageModal(state, action) {
            state.updateImageModal = action.payload;
        },
        setUpdatePdfModal(state, action) {
            state.updatePdfModal = action.payload;
        },
        setLoadingButton(state, action) {
            state.loadingButton = action.payload;
        },
        // inputs data
        setName(state, action) {
            state.name = action.payload;
        },
        setSubName(state, action) {
            state.subName = action.payload;
        },
        setDetails(state, action) {
            state.details = action.payload;
        },
        setVideoLink(state, action) {
            state.videoLink = action.payload;
        },
        setCurrentProductId(state, action) {
            state.currentProductId = action.payload;
        },
        setCategoryId(state, action) {
            state.categoryId = action.payload;
        },
        clearAllInputs(state, action) {
            state.name = '';
            state.subName = '';
            state.details = '';
            state.categoryId = null;
            state.videoLink = '';
        },
    },


    extraReducers: (builder) => {
        // fetch data
        builder.addCase(fetchProducts.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchProducts.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        });
        builder.addCase(fetchProducts.fulfilled, (state, action) => {
            state.products = action.payload;
            state.filteredProducts = action.payload;
            state.isLoading = false;
        });

        // insert data
        builder.addCase(insertProduct.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(insertProduct.rejected, (state, action) => {
            state.loadingButton = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث حطأ ما حاول فيما بعد',
            };
            state.message = newMsg;
        });
        builder.addCase(insertProduct.fulfilled, (state, action) => {
            state.products.push(action.payload);
            state.filteredProducts = state.products;
            state.loadingButton = false;
            state.insertModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'insert',
                text: 'تمت الأضافة بنجاح',
            };
            state.message = newMsg;
        });

        // delete data multie
        builder.addCase(deleteProducts.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(deleteProducts.rejected, (state, action) => {
            state.loadingButton = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteProducts.fulfilled, (state, action) => {
            const deletedProductsIds = action.payload;
            state.products = state.products.filter((product) => !deletedProductsIds.includes(product.id));
            state.filteredProducts = state.filteredProducts.filter((product) => !deletedProductsIds.includes(product.id));
            state.deleteAllModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'delete',
                text: 'تم الحذف بنجاح',
            };
            state.message = newMsg;
        });

        // delete data single
        builder.addCase(deleteProduct.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(deleteProduct.rejected, (state, action) => {
            state.error = action.error;
            state.loadingButton = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteProduct.fulfilled, (state, action) => {
            state.products = state.products.filter((city) => city.id !== action.payload);
            state.filteredProducts = state.filteredProducts.filter((city) => city.id !== action.payload);
            state.deleteModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'delete',
                text: 'تم الحذف بنجاح',
            };
            state.message = newMsg;
        });
        // update data
        builder.addCase(updateProduct.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(updateProduct.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(updateProduct.fulfilled, (state, action) => {
            // Add a success message if needed
            const newMsg = {
                type: 'update',
                text: 'تم التحديث بنجاح',
            };
            state.message = newMsg;
            state.isLoading = false;
        });
        // update Product Image
        builder.addCase(updateProductImage.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(updateProductImage.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(updateProductImage.fulfilled, (state, action) => {
            // Add a success message if needed
            const newMsg = {
                type: 'update',
                text: 'تم التحديث بنجاح',
            };
            state.message = newMsg;
            state.isLoading = false;
        });
        // update Product pdf
        builder.addCase(updateProductPdf.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(updateProductPdf.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(updateProductPdf.fulfilled, (state, action) => {
            // Add a success message if needed
            const newMsg = {
                type: 'update',
                text: 'تم التحديث بنجاح',
            };
            state.message = newMsg;
            state.isLoading = false;
        });

        // product details
        builder.addCase(fetchProductDetails.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchProductDetails.rejected, (state, action) => {
            if (action.error.code === 'ERR_BAD_REQUEST') {
                window.location = '/404';
            }
            state.error = action.error;
            state.isLoading = false;
        });
        builder.addCase(fetchProductDetails.fulfilled, (state, action) => {
            state.productDetails = action.payload.productDetails;
            state.productList = action.payload.productList;
            state.isLoading = false;
        });

    },

});


export const {
    FilteredData,
    setInsertModal,
    setDeleteModal,
    setDeleteAllModal,
    setUpdateImageModal,
    setUpdatePdfModal,
    setName,
    setSubName,
    setDetails,
    setPrice,
    setPoints,
    setVideoLink,
    setCurrentProductId,
    setCountryId,
    setCityId,
    setCategoryId,
    setLoadingButton,
    clearAllInputs,
} = productsSlices.actions;
export default productsSlices.reducer;
