import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getCountries, insertCountryApi, deleteCountriesApi, updateCountryApi, deleteCountryApi } from '../../constant';
import { headersConfig } from '../../config/config';


export const insertCountry = createAsyncThunk(
  'countries/insertCountry',
  async (country) => {
    const response = await axios.post(insertCountryApi,
      country,
      {
        headers: headersConfig.headers,
      },
    );
    return response.data;
  }
);


export const fetchCountries = createAsyncThunk(
  'countries/fetchCountries',
  async () => {
    const response = await axios.post(getCountries,
      {},
      {
        headers: headersConfig.headers,
      },
    );
    return response.data;
  }
);

export const updateCountry = createAsyncThunk(
  'countries/updateCountry',
  async (country) => {
    const response = await axios.post(updateCountryApi,
      country,
      {
        headers: headersConfig.headers,
      },
    );
    return response.data;
  }
);


export const deleteCountries = createAsyncThunk(
  'countries/deleteCountries',
  async (ids) => {
    const body = {
      ids,
    }
    const response = await axios.post(deleteCountriesApi,
      body,
      {
        headers: headersConfig.headers,
      },
    );
    return ids;
  }
);
export const deleteCountry = createAsyncThunk(
  'countries/deleteCountry',
  async (id) => {
    const body = {
      id,
    }
    const response = await axios.post(deleteCountryApi,
      body,
      {
        headers: headersConfig.headers,
      },
    );
    return id;
  }
);


const countriesSlice = createSlice({
  name: 'countries',
  initialState: {
    countries: [],
    filteredCountries: [],
    message: {},
    name: '',
    code: null,
    currentCountryId: null,
    insertModal: false,
    updateModal: false,
    deleteModal: false,
    deleteAllModal: false,
    isLoading: false,
    loadingButton: false,
    error: null,
  },
  reducers: {
    FilteredData(state, action) {
      const filtered = state.countries.filter((item) =>
        Object.values(item).some((val) =>
          val.toString().toLowerCase().includes(action.payload.toLowerCase())
        )
      );
      state.filteredCountries = filtered;
    },

    setInsertModal(state, action) {
      state.insertModal = action.payload;
    },
    setUpdateModal(state, action) {
      state.updateModal = action.payload;
    },
    setDeleteModal(state, action) {
      state.deleteModal = action.payload;
    },
    setDeleteAllModal(state, action) {
      state.deleteAllModal = action.payload;
    },
    setLoadingButton(state, action) {
      state.loadingButton = action.payload;
    },
    // inputs data
    setName(state, action) {
      state.name = action.payload;
    },
    setCode(state, action) {
      state.code = action.payload;
    },
    setCurrentCountryId(state, action) {
      state.currentCountryId = action.payload;
    },
  },


  extraReducers: (builder) => {
    // fetch data
    builder.addCase(fetchCountries.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.countries = action.payload;
      state.filteredCountries = action.payload;
      state.isLoading = false;
    });

    builder.addCase(fetchCountries.rejected, (state, action) => {
      state.error = action.error;
      state.isLoading = false;
    });

    // insert data
    builder.addCase(insertCountry.pending, (state, action) => {
      state.loadingButton = true;
    });
    builder.addCase(insertCountry.rejected, (state, action) => {
      state.loadingButton = false;
      state.error = action.error;
      const newMsg = {
        type: 'error',
        text: 'حدث خطأ ما حاول فيما بعد',
      };
      state.message = newMsg;
    });
    builder.addCase(insertCountry.fulfilled, (state, action) => {
      state.countries.push(action.payload);
      state.filteredCountries = state.countries;
      state.loadingButton = false;
      state.insertModal = false;
      state.loadingButton = false;
      const newMsg = {
        type: 'insert',
        text: 'تمت الأضافة بنجاح',
      };
      state.message = newMsg;
    });

    // delete data multie
    builder.addCase(deleteCountries.pending, (state, action) => {
      state.loadingButton = true;
    });
    builder.addCase(deleteCountries.rejected, (state, action) => {
      state.loadingButton = false;
      state.error = action.error;
      const newMsg = {
        type: 'error',
        text: 'حدث خطأ في الاتصال بالخادم',
      };
      state.message = newMsg;
    });
    builder.addCase(deleteCountries.fulfilled, (state, action) => {
      const deletedCountryIds = action.payload;
      state.countries = state.countries.filter((country) => !deletedCountryIds.includes(country.id));
      state.filteredCountries = state.filteredCountries.filter((country) => !deletedCountryIds.includes(country.id));
      state.deleteAllModal = false;
      state.loadingButton = false;
      const newMsg = {
        type: 'delete',
        text: 'تم الحذف بنجاح',
      };
      state.message = newMsg;
    });
    // delete data single
    builder.addCase(deleteCountry.pending, (state, action) => {
      state.loadingButton = true;
    });
    builder.addCase(deleteCountry.rejected, (state, action) => {
      state.error = action.error;
      state.loadingButton = false;
      const newMsg = {
        type: 'error',
        text: 'حدث خطأ في الاتصال بالخادم',
      };
      state.message = newMsg;
    });
    builder.addCase(deleteCountry.fulfilled, (state, action) => {
      state.countries = state.countries.filter((country) => country.id !== action.payload);
      state.filteredCountries = state.filteredCountries.filter((country) => country.id !== action.payload);
      state.deleteModal = false;
      state.loadingButton = false;
      const newMsg = {
        type: 'delete',
        text: 'تم الحذف بنجاح',
      };
      state.message = newMsg;
    });

    // update data
    builder.addCase(updateCountry.pending, (state, action) => {
      state.loadingButton = true;
    });
    builder.addCase(updateCountry.rejected, (state, action) => {
      state.error = action.error;
      state.loadingButton = false;
      const newMsg = {
        type: 'error',
        text: 'حدث خطأ في الاتصال بالخادم',
      };
      state.message = newMsg;
    });
    builder.addCase(updateCountry.fulfilled, (state, action) => {
      const updatedCountry = action.payload;
      // Update the existing country in the state
      const updatedCountries = state.countries.map((country) =>
        country.id === updatedCountry.id ? updatedCountry : country
      );
      state.countries = updatedCountries;

      // Update the filtered countries as well, if applicable
      const updatedFilteredCountries = state.filteredCountries.map((country) =>
        country.id === updatedCountry.id ? updatedCountry : country
      );
      state.filteredCountries = updatedFilteredCountries;

      // Add a success message if needed
      const newMsg = {
        type: 'update',
        text: 'تم التحديث بنجاح',
      };
      state.message = newMsg;
      state.loadingButton = false;
      state.updateModal = false;
    });

  },

});


export const {
  FilteredData,
  setInsertModal,
  setUpdateModal,
  setDeleteModal,
  setDeleteAllModal,
  setName,
  setCode,
  setCurrentCountryId,
  setLoadingButton,
} = countriesSlice.actions;
export default countriesSlice.reducer;
