import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { deleteMembersApi, updateMemberApi, deleteMemberApi, getMembersApi, insertMemberApi } from '../../constant';
import { headersConfig } from '../../config/config';


export const insertMember = createAsyncThunk(
    'members/insertMember',
    async (member) => {
        const response = await axios.post(insertMemberApi,
            member,
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);


export const fetchMembers = createAsyncThunk(
    'members/fetchMembers',
    async () => {
        const response = await axios.post(getMembersApi,
            {},
            {
                headers: headersConfig.headers,
            },

        );
        return response.data
    }
);

export const updateMember = createAsyncThunk(
    'members/updateMember',
    async (member) => {
        const response = await axios.post(updateMemberApi,
            member,
            {
                headers: headersConfig.headers,
            },

        );
        return response.data;
    }
);


export const deleteMembers = createAsyncThunk(
    'members/deleteMembers',
    async (ids) => {
        const body = {
            ids,
        }
        const response = await axios.post(deleteMembersApi,
            body,
            {
                headers: headersConfig.headers,
            },
        );
        return ids;
    }
);


export const deleteMember = createAsyncThunk(
    'members/deleteMember',
    async (id) => {
        const body = {
            id,
        }
        const response = await axios.post(deleteMemberApi,
            body,
            {
                headers: headersConfig.headers,
            },
        );
        return id;
    }
);


const membersSlices = createSlice({
    name: 'members',
    initialState: {
        members: [],
        filteredMembers: [],
        message: {},
        name: '',
        code: '',
        currentMemberId: null,
        countryId: null,
        cityId: null,
        insertModal: false,
        updateModal: false,
        deleteModal: false,
        deleteAllModal: false,
        isLoading: false,
        loadingButton: false,
        error: null,
    },
    reducers: {
        FilteredData(state, action) {
            const filtered = state.deliverymen.filter((item) =>
                Object.values(item).some((val) =>
                    val.toString().toLowerCase().includes(action.payload.toLowerCase())
                )
            );
            state.filteredMembers = filtered;
        },

        setInsertModal(state, action) {
            state.insertModal = action.payload;
        },
        setUpdateModal(state, action) {
            state.updateModal = action.payload;
        },
        setDeleteModal(state, action) {
            state.deleteModal = action.payload;
        },
        setDeleteAllModal(state, action) {
            state.deleteAllModal = action.payload;
        },
        setLoadingButton(state, action) {
            state.loadingButton = action.payload;
        },
        // inputs data
        setName(state, action) {
            state.name = action.payload;
        },
        setCode(state, action) {
            state.code = action.payload;
        },
        setCurrentMemberId(state, action) {
            state.currentMemberId = action.payload;
        },
        setCountryId(state, action) {
            state.countryId = action.payload;
        },
        setCityId(state, action) {
            state.cityId = action.payload;
        },
    },


    extraReducers: (builder) => {
        // fetch data
        builder.addCase(fetchMembers.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchMembers.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        });
        builder.addCase(fetchMembers.fulfilled, (state, action) => {
            state.deliverymen = action.payload;
            state.filteredMembers = action.payload;
            state.isLoading = false;
        });

        // insert data
        builder.addCase(insertMember.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(insertMember.rejected, (state, action) => {
            state.loadingButton = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'كود التسجيل مستخدم سابقا',
            };
            state.message = newMsg;
        });
        builder.addCase(insertMember.fulfilled, (state, action) => {
            state.deliverymen.push(action.payload);
            state.filteredMembers = state.deliverymen;
            state.loadingButton = false;
            state.insertModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'insert',
                text: 'تمت الأضافة بنجاح',
            };
            state.message = newMsg;
        });

        // delete data multie
        builder.addCase(deleteMembers.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(deleteMembers.rejected, (state, action) => {
            state.loadingButton = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteMembers.fulfilled, (state, action) => {
            const deletedDeliverymenIds = action.payload;
            state.deliverymen = state.deliverymen.filter((deliveryman) => !deletedDeliverymenIds.includes(deliveryman.id));
            state.filteredMembers = state.filteredMembers.filter((deliveryman) => !deletedDeliverymenIds.includes(deliveryman.id));
            state.deleteAllModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'delete',
                text: 'تم الحذف بنجاح',
            };
            state.message = newMsg;
        });

        // delete data single
        builder.addCase(deleteMember.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(deleteMember.rejected, (state, action) => {
            state.error = action.error;
            state.loadingButton = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteMember.fulfilled, (state, action) => {
            state.deliverymen = state.deliverymen.filter((city) => city.id !== action.payload);
            state.filteredMembers = state.filteredMembers.filter((city) => city.id !== action.payload);
            state.deleteModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'delete',
                text: 'تم الحذف بنجاح',
            };
            state.message = newMsg;
        });
        // update data
        builder.addCase(updateMember.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(updateMember.rejected, (state, action) => {
            state.error = action.error;
            state.loadingButton = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(updateMember.fulfilled, (state, action) => {
            const updatedCountry = action.payload;
            // Update the existing city in the state
            const updatedCountries = state.deliverymen.map((city) =>
                city.id === updatedCountry.id ? updatedCountry : city
            );
            state.deliverymen = updatedCountries;

            // Update the filtered deliverymen as well, if applicable
            const updatedFilteredCountries = state.filteredMembers.map((city) =>
                city.id === updatedCountry.id ? updatedCountry : city
            );
            state.filteredMembers = updatedFilteredCountries;

            // Add a success message if needed
            const newMsg = {
                type: 'update',
                text: 'تم التحديث بنجاح',
            };
            state.message = newMsg;
            state.loadingButton = false;
            state.updateModal = false;
        });

    },

});


export const {
    FilteredData,
    setInsertModal,
    setUpdateModal,
    setDeleteModal,
    setDeleteAllModal,
    setName,
    setCode,
    setCurrentMemberId,
    setCountryId,
    setCityId,
    setLoadingButton,
} = membersSlices.actions;
export default membersSlices.reducer;
