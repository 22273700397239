import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getMembersRequestApi, deleteMemberRequestApi, deleteMembersRequestApi } from '../../constant';
import { headersConfig } from '../../config/config';





export const fetchMembersRequest = createAsyncThunk(
    'membersRequest/fetchMembersRequest',
    async () => {
        const response = await axios.post(getMembersRequestApi,
            {},
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);



export const deleteMembersRequest = createAsyncThunk(
    'membersRequest/deleteMembersRequest',
    async (ids) => {
        const body = {
            ids,
        }
        const response = await axios.post(deleteMembersRequestApi,
            body,
            {
                headers: headersConfig.headers,
            },
        );
        return ids;
    }
);


export const deleteMemberRequest = createAsyncThunk(
    'membersRequest/deleteMemberRequest',
    async (id) => {
        const body = {
            id,
        }
        const response = await axios.post(deleteMemberRequestApi,
            body,
            {
                headers: headersConfig.headers,
            },
        );
        return id;
    }
);


const membersRequestSlices = createSlice({
    name: 'membersRequest',
    initialState: {
        membersRequest: [],
        filteredMembersRequest: [],
        message: {},
        currentMemberReqId: null,
        currentMemberReq: null,
        deleteModal: false,
        deleteAllModal: false,
        detailsModal: false,
        isLoading: false,
        loadingButton: false,
        error: null,
    },
    reducers: {
        FilteredData(state, action) {
            const filtered = state.membersRequest.filter((item) =>
                Object.values(item).some((val) =>
                    val.toString().toLowerCase().includes(action.payload.toLowerCase())
                )
            );
            state.filteredMembersRequest = filtered;
        },



        setCurrentMemberReqId(state, action) {
            state.currentMemberReqId = action.payload;
        },
        setCurrentMemberReq(state, action) {
            state.currentMemberReq = action.payload;
        },

        setDeleteModal(state, action) {
            state.deleteModal = action.payload;
        },
        setDeleteAllModal(state, action) {
            state.deleteAllModal = action.payload;
        },
        setDetailsModal(state, action) {
            state.detailsModal = action.payload;
        },
        setLoadingButton(state, action) {
            state.loadingButton = action.payload;
        },
    },


    extraReducers: (builder) => {
        // fetch data
        builder.addCase(fetchMembersRequest.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchMembersRequest.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        });
        builder.addCase(fetchMembersRequest.fulfilled, (state, action) => {
            state.membersRequest = action.payload;
            state.filteredMembersRequest = action.payload;
            state.isLoading = false;
        });

        // delete data multie
        builder.addCase(deleteMembersRequest.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(deleteMembersRequest.rejected, (state, action) => {
            state.loadingButton = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteMembersRequest.fulfilled, (state, action) => {
            const deletedMembersRequestIds = action.payload;
            state.membersRequest = state.membersRequest.filter((m) => !deletedMembersRequestIds.includes(m.id));
            state.filteredMembersRequest = state.filteredMembersRequest.filter((m) => !deletedMembersRequestIds.includes(m.id));
            state.deleteAllModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'delete',
                text: 'تم الحذف بنجاح',
            };
            state.message = newMsg;
        });

        // delete data single
        builder.addCase(deleteMemberRequest.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(deleteMemberRequest.rejected, (state, action) => {
            state.error = action.error;
            state.loadingButton = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteMemberRequest.fulfilled, (state, action) => {
            state.membersRequest = state.membersRequest.filter((m) => m.id !== action.payload);
            state.filteredMembersRequest = state.filteredMembersRequest.filter((m) => m.id !== action.payload);
            state.deleteModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'delete',
                text: 'تم الحذف بنجاح',
            };
            state.message = newMsg;
        });
    },

});


export const {
    FilteredData,
    setCurrentMemberReqId,
    setCurrentMemberReq,
    setDeleteModal,
    setDeleteAllModal,
    setDetailsModal,
    setLoadingButton,
} = membersRequestSlices.actions;
export default membersRequestSlices.reducer;
