// const server = 'http://localhost:3000/';
const server = 'https://server.dxn.family/';
const apiLink = server + 'api/';
const imagesUrl = server + 'uploads/products/images/';
const filesUrl = server + 'uploads/products/pdfs/';

// admins
const loginApi = apiLink + 'admins/login';
// countries
const getCountries = apiLink + 'countries/get-countries';
const insertCountryApi = apiLink + 'countries/insert-country';
const updateCountryApi = apiLink + 'countries/update-country';
const deleteCountryApi = apiLink + 'countries/delete-country';
const deleteCountriesApi = apiLink + 'countries/delete-countries';
// cities
const getCitiesApi = apiLink + 'cities/get-cities';
const insertCityApi = apiLink + 'cities/insert-city';
const updateCityApi = apiLink + 'cities/update-city';
const deleteCityApi = apiLink + 'cities/delete-city';
const deleteCitesApi = apiLink + 'cities/delete-cities';
// currencies
const getCurrenciesApi = apiLink + 'currencies/get-currencies';
const insertCurrencyApi = apiLink + 'currencies/insert-currency';
const updateCurrencyApi = apiLink + 'currencies/update-currency';
const deleteCurrencyApi = apiLink + 'currencies/delete-currency';
const deleteCurrenciesApi = apiLink + 'currencies/delete-currencies';
// deliverymen
const getDeliverymenApi = apiLink + 'deliverymen/get-deliverymen';
const insertDeliverymanApi = apiLink + 'deliverymen/insert-deliveryman';
const updateDeliverymanApi = apiLink + 'deliverymen/update-deliveryman';
const deleteDeliverymanApi = apiLink + 'deliverymen/delete-deliveryman';
const deleteDeliverymenApi = apiLink + 'deliverymen/delete-deliverymen';
// members
const getMembersApi = apiLink + 'members/get-members';
const insertMemberApi = apiLink + 'members/insert-member';
const updateMemberApi = apiLink + 'members/update-member';
const deleteMemberApi = apiLink + 'members/delete-member';
const deleteMembersApi = apiLink + 'members/delete-members';
// categories
const getCategoriesApi = apiLink + 'categories/get-categories';
const insertCategoryApi = apiLink + 'categories/insert-category';
const updateCategoryApi = apiLink + 'categories/update-category';
const deleteCategoryApi = apiLink + 'categories/delete-category';
const deleteCategoriesApi = apiLink + 'categories/delete-categories';
// products 
const getProductsApi = apiLink + 'products/get-products';
const insertProductApi = apiLink + 'products/insert-product';
const updateProductApi = apiLink + 'products/update-product';
const deleteProductApi = apiLink + 'products/delete-product';
const deleteProductsApi = apiLink + 'products/delete-products';
const productDetailsApi = apiLink + 'products/get-product';
const updateProductImageApi = apiLink + 'products/update-productImage';
const updateProductPdfApi = apiLink + 'products/update-productPdf';

// productsList 
const insertProductListApi = apiLink + 'productsList/insert';
const updateProductListApi = apiLink + 'productsList/update-productList';
const deleteProductListApi = apiLink + 'productsList/delete-productList';
const deleteProductsListApi = apiLink + 'productsList/delete-productsList';

// videosCategories
const getVideosCategoriesApi = apiLink + 'videosCategories/get-categories';
const insertVideosCategoryApi = apiLink + 'videosCategories/insert-category';
const updateVideosCategoryApi = apiLink + 'videosCategories/update-category';
const deleteVideosCategoryApi = apiLink + 'videosCategories/delete-category';
const deleteVideosCategoriesApi = apiLink + 'videosCategories/delete-categories';

// videos 
const getVideosApi = apiLink + 'videos/get-videos';
const insertVideoApi = apiLink + 'videos/insert-video';
const updateVideoApi = apiLink + 'videos/update-video';
const deleteVideoApi = apiLink + 'videos/delete-video';
const deleteVideosApi = apiLink + 'videos/delete-videos';

// branches 
const getBranchesApi = apiLink + 'branches/get-branches';
const insertBranchApi = apiLink + 'branches/insert-branch';
const updateBranchApi = apiLink + 'branches/update-branch';
const deleteBranchesApi = apiLink + 'branches/delete-branches';
const deleteBranchApi = apiLink + 'branches/delete-branch';


// members request 
const getMembersRequestApi = apiLink + 'membershipRequests/get-requests';
const deleteMembersRequestApi = apiLink + 'membershipRequests/delete-membersRequest';
const deleteMemberRequestApi = apiLink + 'membershipRequests/delete-memberRequest';


export {
    imagesUrl,
    filesUrl,
    // admins
    loginApi,
    // countries
    getCountries,
    insertCountryApi,
    updateCountryApi,
    deleteCountryApi,
    deleteCountriesApi,
    // cities
    getCitiesApi,
    insertCityApi,
    updateCityApi,
    deleteCityApi,
    deleteCitesApi,
    // currencies
    getCurrenciesApi,
    insertCurrencyApi,
    updateCurrencyApi,
    deleteCurrencyApi,
    deleteCurrenciesApi,
    // deliverymenpi
    getDeliverymenApi,
    insertDeliverymanApi,
    updateDeliverymanApi,
    deleteDeliverymanApi,
    deleteDeliverymenApi,
    // member
    getMembersApi,
    insertMemberApi,
    updateMemberApi,
    deleteMemberApi,
    deleteMembersApi,
    // categories
    getCategoriesApi,
    insertCategoryApi,
    updateCategoryApi,
    deleteCategoryApi,
    deleteCategoriesApi,
    // products
    getProductsApi,
    insertProductApi,
    updateProductApi,
    deleteProductApi,
    deleteProductsApi,
    productDetailsApi,
    updateProductImageApi,
    updateProductPdfApi,
    // productsList
    insertProductListApi,
    deleteProductListApi,
    deleteProductsListApi,
    updateProductListApi,
    // videosCategories
    getVideosCategoriesApi,
    insertVideosCategoryApi,
    updateVideosCategoryApi,
    deleteVideosCategoryApi,
    deleteVideosCategoriesApi,
    // videos
    getVideosApi,
    insertVideoApi,
    updateVideoApi,
    deleteVideoApi,
    deleteVideosApi,
    // branches
    getBranchesApi,
    insertBranchApi,
    updateBranchApi,
    deleteBranchesApi,
    deleteBranchApi,
    // members request
    getMembersRequestApi,
    deleteMemberRequestApi,
    deleteMembersRequestApi,
}