import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { insertVideoApi, deleteVideosApi, updateVideoApi, deleteVideoApi, getVideosApi } from '../../constant';
import { headersConfig } from '../../config/config';



export const insertVideo = createAsyncThunk(
    'videos/insertVideo',
    async (video) => {
        const response = await axios.post(insertVideoApi,
            video,
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);


export const fetchVideos = createAsyncThunk(
    'videos/fetchVideos',
    async () => {
        const response = await axios.post(getVideosApi,
            {},
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);

export const updateVideo = createAsyncThunk(
    'videos/updateVideo',
    async (category) => {
        const response = await axios.post(updateVideoApi, category,
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);


export const deleteVideos = createAsyncThunk(
    'videos/deleteVideos',
    async (ids) => {
        const body = {
            ids,
        }
        const response = await axios.post(deleteVideosApi,
            body,
            {
                headers: headersConfig.headers,
            },
        );
        return ids;
    }
);


export const deleteVideo = createAsyncThunk(
    'videos/deleteVideo',
    async (id) => {
        const body = {
            id,
        }
        const response = await axios.post(deleteVideoApi,
            body,
            {
                headers: headersConfig.headers,
            },
        );
        return id;
    }
);


const videosSlices = createSlice({
    name: 'videos',
    initialState: {
        videos: [],
        filteredVideos: [],
        message: {},
        title: '',
        link: '',
        categoryId: null,
        currentVideoId: null,
        insertModal: false,
        updateModal: false,
        deleteModal: false,
        deleteAllModal: false,
        isLoading: false,
        loadingButton: false,
        error: null,
    },
    reducers: {
        FilteredData(state, action) {
            const filtered = state.videos.filter((item) =>
                Object.values(item).some((val) =>
                    val.toString().toLowerCase().includes(action.payload.toLowerCase())
                )
            );
            state.filteredVideos = filtered;
        },

        setInsertModal(state, action) {
            state.insertModal = action.payload;
        },
        setUpdateModal(state, action) {
            state.updateModal = action.payload;
        },
        setDeleteModal(state, action) {
            state.deleteModal = action.payload;
        },
        setDeleteAllModal(state, action) {
            state.deleteAllModal = action.payload;
        },
        setLoadingButton(state, action) {
            state.loadingButton = action.payload;
        },
        // inputs data
        setTitle(state, action) {
            state.title = action.payload;
        },
        setLink(state, action) {
            state.link = action.payload;
        },
        setCategoryId(state, action) {
            state.categoryId = action.payload;
        },
        setcurrentVideoId(state, action) {
            state.currentVideoId = action.payload;
        },
    },


    extraReducers: (builder) => {
        // fetch data
        builder.addCase(fetchVideos.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchVideos.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        });
        builder.addCase(fetchVideos.fulfilled, (state, action) => {
            state.videos = action.payload;
            state.filteredVideos = action.payload;
            state.isLoading = false;
        });

        // insert data
        builder.addCase(insertVideo.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(insertVideo.rejected, (state, action) => {
            state.loadingButton = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ ما حاول فيما بعد',
            };
            state.message = newMsg;
        });
        builder.addCase(insertVideo.fulfilled, (state, action) => {
            state.videos.push(action.payload);
            state.filteredVideos = state.videos;
            state.loadingButton = false;
            state.insertModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'insert',
                text: 'تمت الأضافة بنجاح',
            };
            state.message = newMsg;
        });

        // delete data multie
        builder.addCase(deleteVideos.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(deleteVideos.rejected, (state, action) => {
            state.loadingButton = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteVideos.fulfilled, (state, action) => {
            const deletedVideosIds = action.payload;
            state.videos = state.videos.filter((video) => !deletedVideosIds.includes(video.id));
            state.filteredVideos = state.filteredVideos.filter((video) => !deletedVideosIds.includes(video.id));
            state.deleteAllModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'delete',
                text: 'تم الحذف بنجاح',
            };
            state.message = newMsg;
        });

        // delete data single
        builder.addCase(deleteVideo.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(deleteVideo.rejected, (state, action) => {
            state.error = action.error;
            state.loadingButton = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteVideo.fulfilled, (state, action) => {
            state.videos = state.videos.filter((video) => video.id !== action.payload);
            state.filteredVideos = state.filteredVideos.filter((video) => video.id !== action.payload);
            state.deleteModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'delete',
                text: 'تم الحذف بنجاح',
            };
            state.message = newMsg;
        });
        // update data
        builder.addCase(updateVideo.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(updateVideo.rejected, (state, action) => {
            state.error = action.error;
            state.loadingButton = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(updateVideo.fulfilled, (state, action) => {
            const updatedVideo = action.payload;
            const updatedVideos = state.videos.map((video) =>
                video.id === updatedVideo.id ? updatedVideo : video
            );
            state.videos = updatedVideos;
            const updatedfilteredVideos = state.filteredVideos.map((video) =>
                video.id === updatedVideo.id ? updatedVideo : video
            );
            state.filteredVideos = updatedfilteredVideos;

            // success message 
            const newMsg = {
                type: 'update',
                text: 'تم التحديث بنجاح',
            };
            state.message = newMsg;
            state.loadingButton = false;
            state.updateModal = false;
        });

    },

});


export const {
    FilteredData,
    setInsertModal,
    setUpdateModal,
    setDeleteModal,
    setDeleteAllModal,
    setTitle,
    setLink,
    setCategoryId,
    setcurrentVideoId,
    setLoadingButton,
} = videosSlices.actions;
export default videosSlices.reducer;
