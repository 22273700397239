import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getCitiesApi, insertCityApi, deleteCitesApi, updateCityApi, deleteCityApi } from '../../constant';
import { headersConfig } from '../../config/config';


export const insertCity = createAsyncThunk(
    'cities/insertCity',
    async (city) => {
        const response = await axios.post(insertCityApi, city,
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);

export const fetchCities = createAsyncThunk(
    'cities/fetchCities',
    async () => {
        const response = await axios.post(getCitiesApi,
            {},
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);

export const updateCity = createAsyncThunk(
    'cities/updateCity',
    async (city) => {
        const response = await axios.post(updateCityApi,
            city,
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);


export const deleteCites = createAsyncThunk(
    'cities/deleteCites',
    async (ids) => {
        const body = {
            ids,
        }
        const response = await axios.post(deleteCitesApi,
            body,
            {
                headers: headersConfig.headers,
            },
        );
        return ids;
    }
);
export const deleteCity = createAsyncThunk(
    'cities/deleteCity',
    async (id) => {
        const body = {
            id,
        }
        const response = await axios.post(deleteCityApi,
            body,
            {
                headers: headersConfig.headers,
            },
        );
        return id;
    }
);


const citiesSlices = createSlice({
    name: 'cities',
    initialState: {
        cities: [],
        filteredCities: [],
        message: {},
        name: '',
        currentCityId: null,
        countryId: null,
        insertModal: false,
        updateModal: false,
        deleteModal: false,
        deleteAllModal: false,
        isLoading: false,
        loadingButton: false,
        error: null,
    },
    reducers: {
        FilteredData(state, action) {
            const filtered = state.cities.filter((item) =>
                Object.values(item).some((val) =>
                    val.toString().toLowerCase().includes(action.payload.toLowerCase())
                )
            );
            state.filteredCities = filtered;
        },

        setInsertModal(state, action) {
            state.insertModal = action.payload;
        },
        setUpdateModal(state, action) {
            state.updateModal = action.payload;
        },
        setDeleteModal(state, action) {
            state.deleteModal = action.payload;
        },
        setDeleteAllModal(state, action) {
            state.deleteAllModal = action.payload;
        },
        setLoadingButton(state, action) {
            state.loadingButton = action.payload;
        },
        // inputs data
        setName(state, action) {
            state.name = action.payload;
        },
        setCountryId(state, action) {
            state.countryId = action.payload;
        },
        setCurrentCityId(state, action) {
            state.currentCityId = action.payload;
        },
    },


    extraReducers: (builder) => {
        // fetch data
        builder.addCase(fetchCities.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchCities.fulfilled, (state, action) => {
            state.cities = action.payload;
            state.filteredCities = action.payload;
            state.isLoading = false;
        });

        builder.addCase(fetchCities.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        });

        // insert data
        builder.addCase(insertCity.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(insertCity.rejected, (state, action) => {
            state.loadingButton = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ ما حاول فيما بعد',
            };
            state.message = newMsg;
        });
        builder.addCase(insertCity.fulfilled, (state, action) => {
            state.cities.push(action.payload);
            state.filteredCities = state.cities;
            state.loadingButton = false;
            state.insertModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'insert',
                text: 'تمت الأضافة بنجاح',
            };
            state.message = newMsg;
        });

        // delete data multie
        builder.addCase(deleteCites.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(deleteCites.rejected, (state, action) => {
            state.loadingButton = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteCites.fulfilled, (state, action) => {
            const deletedCityIds = action.payload;
            state.cities = state.cities.filter((city) => !deletedCityIds.includes(city.id));
            state.filteredCities = state.filteredCities.filter((city) => !deletedCityIds.includes(city.id));
            state.deleteAllModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'delete',
                text: 'تم الحذف بنجاح',
            };
            state.message = newMsg;
        });

        // delete data single
        builder.addCase(deleteCity.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(deleteCity.rejected, (state, action) => {
            state.error = action.error;
            state.loadingButton = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteCity.fulfilled, (state, action) => {
            state.cities = state.cities.filter((city) => city.id !== action.payload);
            state.filteredCities = state.filteredCities.filter((city) => city.id !== action.payload);
            state.deleteModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'delete',
                text: 'تم الحذف بنجاح',
            };
            state.message = newMsg;
        });

        // update data
        builder.addCase(updateCity.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(updateCity.rejected, (state, action) => {
            state.error = action.error;
            state.loadingButton = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(updateCity.fulfilled, (state, action) => {
            const updatedCity = action.payload;
            state.loadingButton = false;
            // Update the existing city in the state
            const updatedCountries = state.cities.map((city) =>
                city.id === updatedCity.id ? updatedCity : city
            );
            state.cities = updatedCountries;

            // Update the filtered cities as well, if applicable
            const updatedFilteredCities = state.filteredCities.map((city) =>
                city.id === updatedCity.id ? updatedCity : city
            );
            state.filteredCities = updatedFilteredCities;

            // Add a success message if needed
            const newMsg = {
                type: 'update',
                text: 'تم التحديث بنجاح',
            };
            state.message = newMsg;
            state.loadingButton = false;
            state.updateModal = false;
        });


    },

});

export const {
    FilteredData,
    setInsertModal,
    setUpdateModal,
    setDeleteModal,
    setDeleteAllModal,
    setName,
    setCountryId,
    setCurrentCityId,
    setLoadingButton,
} = citiesSlices.actions;
export default citiesSlices.reducer;
