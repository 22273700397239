import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { updateProductListApi, insertProductListApi, deleteProductsListApi, deleteProductListApi } from '../../constant';
import { headersConfig } from '../../config/config';


export const insertProductList = createAsyncThunk(
    'productsList/insertProductList',
    async (productList) => {
        const response = await axios.post(insertProductListApi,
            productList,
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);



export const updateProductList = createAsyncThunk(
    'productsList/updateProductList',
    async (product) => {
        const response = await axios.post(updateProductListApi,
            product,
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);


export const deleteProductsList = createAsyncThunk(
    'productsList/deleteProductsList',
    async (ids) => {
        const body = {
            ids,
        }
        const response = await axios.post(deleteProductsListApi,
            body,
            {
                headers: headersConfig.headers,
            },
        );
        return ids;
    }
);


export const deleteProductList = createAsyncThunk(
    'productsList/deleteProductList',
    async (id) => {
        const body = {
            id,
        }
        const response = await axios.post(deleteProductListApi,
            body,
            {
                headers: headersConfig.headers,
            },

        );
        return id;
    }
);


const productsListSlices = createSlice({
    name: 'productsList',
    initialState: {
        message: {},
        insertModal: false,
        updateModal: false,
        deleteModal: false,
        deleteAllModal: false,
        loadingButton: false,
        error: null,
        price: null,
        points: null,
        countryId: null,
        currentProductListId: null,
    },
    reducers: {

        setInsertModal(state, action) {
            state.insertModal = action.payload;
        },
        setUpdateModal(state, action) {
            state.updateModal = action.payload;
        },
        setDeleteModal(state, action) {
            state.deleteModal = action.payload;
        },
        setDeleteAllModal(state, action) {
            state.deleteAllModal = action.payload;
        },
        setLoadingButton(state, action) {
            state.loadingButton = action.payload;
        },
        setCurrentProductListId(state, action) {
            state.currentProductListId = action.payload;
        },
        // inputs data

        setPrice(state, action) {
            state.price = action.payload;
        },

        setPoints(state, action) {
            state.points = action.payload;
        },
        setCountryId(state, action) {
            state.countryId = action.payload;
        },
        clearAllInputs(state, action) {
            state.price = null;
            state.points = null;
            state.countryId = null;
        },
    },


    extraReducers: (builder) => {


        // insert data
        builder.addCase(insertProductList.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(insertProductList.rejected, (state, action) => {
            state.loadingButton = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث حطأ ما حاول فيما بعد',
            };
            state.message = newMsg;
        });
        builder.addCase(insertProductList.fulfilled, (state, action) => {
            state.loadingButton = false;
            state.insertModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'insert',
                text: 'تمت الأضافة بنجاح',
            };
            state.message = newMsg;
            window.location.reload();
        });

        // delete data multie
        builder.addCase(deleteProductsList.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(deleteProductsList.rejected, (state, action) => {
            state.loadingButton = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteProductsList.fulfilled, (state, action) => {
            state.deleteAllModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'delete',
                text: 'تم الحذف بنجاح',
            };
            state.message = newMsg;
            window.location.reload();
        });

        // delete data single
        builder.addCase(deleteProductList.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(deleteProductList.rejected, (state, action) => {
            state.error = action.error;
            state.loadingButton = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteProductList.fulfilled, (state, action) => {
            state.deleteModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'delete',
                text: 'تم الحذف بنجاح',
            };
            state.message = newMsg;
            window.location.reload();
        });
        // update data
        builder.addCase(updateProductList.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(updateProductList.rejected, (state, action) => {
            state.error = action.error;
            state.loadingButton = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(updateProductList.fulfilled, (state, action) => {
            // Add a success message if needed
            const newMsg = {
                type: 'update',
                text: 'تم التحديث بنجاح',
            };
            state.message = newMsg;
            state.loadingButton = false;
            window.location.reload();
        });

    },

});


export const {
    setInsertModal,
    setUpdateModal,
    setDeleteModal,
    setDeleteAllModal,
    setCurrentProductListId,
    setPrice,
    setPoints,
    setCountryId,
    setLoadingButton,
    clearAllInputs,
} = productsListSlices.actions;
export default productsListSlices.reducer;
