import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { insertBranchApi, deleteBranchesApi, deleteBranchApi, getBranchesApi, updateBranchApi } from '../../constant';
import { headersConfig } from '../../config/config';



export const insertBranch = createAsyncThunk(
    'branches/insertBranch',
    async (branch) => {
        const response = await axios.post(insertBranchApi,
            branch,
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);


export const fetchBranches = createAsyncThunk(
    'branches/fetchBranches',
    async () => {
        const response = await axios.post(getBranchesApi,
            {},
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);

export const updateBranch = createAsyncThunk(
    'branches/updateBranch',
    async (category) => {
        const response = await axios.post(updateBranchApi, category,
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);


export const deleteBranches = createAsyncThunk(
    'branches/deleteBranches',
    async (ids) => {
        const body = {
            ids,
        }
        const response = await axios.post(deleteBranchesApi,
            body,
            {
                headers: headersConfig.headers,
            },
        );
        return ids;
    }
);


export const deleteBranch = createAsyncThunk(
    'branches/deleteBranch',
    async (id) => {
        const body = {
            id,
        }
        const response = await axios.post(deleteBranchApi,
            body,
            {
                headers: headersConfig.headers,
            },
        );
        return id;
    }
);


const branchesSlices = createSlice({
    name: 'branches',
    initialState: {
        branches: [],
        filteredBranches: [],
        message: {},
        address: '',
        phone: '',
        mapLink: '',
        videoLink: '',
        countryId: null,
        cityId: null,
        currentBranchId: null,
        insertModal: false,
        updateModal: false,
        deleteModal: false,
        deleteAllModal: false,
        isLoading: false,
        loadingButton: false,
        error: null,
    },
    reducers: {
        FilteredData(state, action) {
            const filtered = state.branches.filter((item) =>
                Object.values(item).some((val) =>
                    val.toString().toLowerCase().includes(action.payload.toLowerCase())
                )
            );
            state.filteredBranches = filtered;
        },

        setInsertModal(state, action) {
            state.insertModal = action.payload;
        },
        setUpdateModal(state, action) {
            state.updateModal = action.payload;
        },
        setDeleteModal(state, action) {
            state.deleteModal = action.payload;
        },
        setDeleteAllModal(state, action) {
            state.deleteAllModal = action.payload;
        },
        setLoadingButton(state, action) {
            state.loadingButton = action.payload;
        },
        // inputs data
        setAddress(state, action) {
            state.address = action.payload;
        },
        setPhone(state, action) {
            state.phone = action.payload;
        },
        setMapLink(state, action) {
            state.mapLink = action.payload;
        },
        setVideoLink(state, action) {
            state.videoLink = action.payload;
        },
        setcurrentBranchId(state, action) {
            state.currentBranchId = action.payload;
        },
        setCountryId(state, action) {
            state.countryId = action.payload;
        },
        setCityId(state, action) {
            state.cityId = action.payload;
        },

    },


    extraReducers: (builder) => {
        // fetch data
        builder.addCase(fetchBranches.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchBranches.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        });
        builder.addCase(fetchBranches.fulfilled, (state, action) => {
            state.branches = action.payload;
            state.filteredBranches = action.payload;
            state.isLoading = false;
        });

        // insert data
        builder.addCase(insertBranch.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(insertBranch.rejected, (state, action) => {
            state.loadingButton = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ ما حاول فيما بعد',
            };
            state.message = newMsg;
        });
        builder.addCase(insertBranch.fulfilled, (state, action) => {
            state.branches.push(action.payload);
            state.filteredBranches = state.branches;
            state.loadingButton = false;
            state.insertModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'insert',
                text: 'تمت الأضافة بنجاح',
            };
            state.message = newMsg;
        });

        // delete data multie
        builder.addCase(deleteBranches.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(deleteBranches.rejected, (state, action) => {
            state.loadingButton = false;
            state.error = action.error;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteBranches.fulfilled, (state, action) => {
            const deletedBranchesIds = action.payload;
            state.branches = state.branches.filter((branch) => !deletedBranchesIds.includes(branch.id));
            state.filteredBranches = state.filteredBranches.filter((branch) => !deletedBranchesIds.includes(branch.id));
            state.deleteAllModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'delete',
                text: 'تم الحذف بنجاح',
            };
            state.message = newMsg;
        });

        // delete data single
        builder.addCase(deleteBranch.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(deleteBranch.rejected, (state, action) => {
            state.error = action.error;
            state.loadingButton = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(deleteBranch.fulfilled, (state, action) => {
            state.branches = state.branches.filter((branch) => branch.id !== action.payload);
            state.filteredBranches = state.filteredBranches.filter((branch) => branch.id !== action.payload);
            state.deleteModal = false;
            state.loadingButton = false;
            const newMsg = {
                type: 'delete',
                text: 'تم الحذف بنجاح',
            };
            state.message = newMsg;
        });
        // update data
        builder.addCase(updateBranch.pending, (state, action) => {
            state.loadingButton = true;
        });
        builder.addCase(updateBranch.rejected, (state, action) => {
            state.error = action.error;
            state.loadingButton = false;
            const newMsg = {
                type: 'error',
                text: 'حدث خطأ في الاتصال بالخادم',
            };
            state.message = newMsg;
        });
        builder.addCase(updateBranch.fulfilled, (state, action) => {
            const updatedBranch = action.payload;
            const updatedBranchs = state.branches.map((branch) =>
                branch.id === updatedBranch.id ? updatedBranch : branch
            );
            state.branches = updatedBranchs;
            const updatedfilteredBranches = state.filteredBranches.map((branch) =>
                branch.id === updatedBranch.id ? updatedBranch : branch
            );
            state.filteredBranches = updatedfilteredBranches;

            // success message 
            const newMsg = {
                type: 'update',
                text: 'تم التحديث بنجاح',
            };
            state.message = newMsg;
            state.loadingButton = false;
            state.updateModal = false;
        });

    },

});


export const {
    FilteredData,
    setInsertModal,
    setUpdateModal,
    setDeleteModal,
    setDeleteAllModal,
    setAddress,
    setPhone,
    setMapLink,
    setVideoLink,
    setCountryId,
    setCityId,
    setcurrentBranchId,
    setLoadingButton,
} = branchesSlices.actions;
export default branchesSlices.reducer;
